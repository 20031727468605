
import imageFront from '@/assets/product/product1.png'
import priceHelper from '@/plugins/price'

export default {
  name: 'ProductCard',
  components: {
    IconEsgotado: () => import('@/assets/icons/esgotado.svg')
  },
  props: {
    productCall: {
      type: Object,
      default() {}
    },
    indexFor: {
      type: Number,
      default: 6
    },
    windowW: {
      type: Number,
      default: 450
    },
    cardType: {
      type: String,
      default: 'category'
    },
  },
  data: () => ({
    currentSelected: 0,
    hover: false,
    product: {
      imageFront: imageFront,
      link: '/',
      title: '',
      brand: '',
      sku: '',
      price: null,
      sale_price: null,
      installment: {
        months: null,
        amount: ''
      },
      oneInstallment: null,
      new: false,
      kids: false,
      bestSeller: false,
      discountPercent: null,
      collection: [],
      freeShipping: false
    },
    swiperOption: {
      initialSlide: 0,
      slidesPerView: 10,
      spaceBetween: 0,
      slidesPerGroup: 8,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    imagePreLoad: '',
    imageWidth: 450
  }),
  head () {
    if(this.indexFor <= 5 && this.imagePreLoad !== '') {
    return {
      link: [
        { rel: 'preload', as: 'image', href: this.imagePreLoad, async: true}
      ]
    }
  }
  },
  async created() {
    if(this.indexFor <= 5) {
      await this.preloadImages()
    }
    if (this.cardType !== undefined) {
      this.product.imageFront = this.productCall.mainImage.replace('{width}', this.windowW)
      this.product.link = this.productCall.url
      this.product.title = this.productCall.name
      this.product.brand = this.productCall.lensesBrand
      this.product.sku = this.productCall.sku
      this.product.price = this.productCall.price
      this.product.sale_price = this.productCall.finalPrice
      this.product.installment.months = this.productCall.installment.qty
      this.product.installment.amount = this.productCall.installment.value
      this.product.oneInstallment = this.productCall.installment.pixOneInstallment
      if (this.cardType === 'category') {
        this.product.new = this.productCall.stamps.new
        this.product.kids = this.productCall.stamps.kids
        this.product.bestSeller = this.productCall.stamps.bestSeller
        this.product.collection = this.productCall.stamps.collection
      }
      this.product.discountPercent = this.productCall.discountPercent
      this.product.freeShipping = this.productCall.freeShipping
    }
  },
  mounted() {
    if(this.indexFor <= 5 && this.productCall !== '') {
      this.usePreloadedImages()
    }
    this.resizedImage()
  },
  methods: {
    formatPrice(value) {
      return priceHelper.formatPrice(value)
    },
    async preloadImages() {
      this.imagePreLoad = this.productCall.mainImage.replace('{width}', this.windowW)
    },
    usePreloadedImages() {
      const img = new Image()
      img.src = this.imagePreLoad
    },
    resizedImage() {
      this.imageWidth = this.windowW
    }
  }
}
